import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import styled from "styled-components";
import {
  innerWidth,
  subFont,
  screen,
  mainBlue,
  darkPurple,
} from "../components/variables";

const Wrapper = styled.div`
  min-height: 60vh;
  padding: 120px 0 60px 0;
  @media ${screen.xsmall} {
    padding: 160px 0 120px 0;
    min-height: 70vh;
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    .title {
      font-family: ${subFont};
      font-size: 2.38rem;
      line-height: 1.05;
      margin: 0 0 34px 0;
      @media ${screen.xsmall} {
        font-size: 3.7rem;
      }
      @media ${screen.small} {
        font-size: 3.9rem;
      }
      @media ${screen.medium} {
        font-size: 4.15rem;
      }
    }

    .description {
      max-width: 1000px;

      h3 {
        font-size: 1.25rem;
        line-height: 1.4;
        margin: 45px 0 15px 0;
        @media ${screen.xsmall} {
          font-size: 1.6rem;
          margin: 75px 0 20px 0;
        }
        @media ${screen.small} {
          font-size: 1.7rem;
        }
        @media ${screen.medium} {
          font-size: 1.8rem;
        }
      }

      p {
        font-size: 0.95rem;
        margin: 0 0 20px 0;
        @media ${screen.xsmall} {
          font-size: 1.15rem;
        }
        @media ${screen.small} {
          font-size: 1.2rem;
        }
        @media ${screen.medium} {
          font-size: 1.25rem;
        }
      }

      a {
        color: ${darkPurple};
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainBlue};
          }
        }
      }

      li {
        font-size: 0.95rem;
        margin: 0 0 20px 0;
        line-height: 1.4;
        @media ${screen.xsmall} {
          font-size: 1.15rem;
        }
        @media ${screen.small} {
          font-size: 1.2rem;
        }
        @media ${screen.medium} {
          font-size: 1.25rem;
        }
      }

      ul,
      ol {
        padding: 0 0 0 25px;
        margin: 20px 0;
        @media ${screen.xsmall} {
          padding: 0 0 0 40px;
        }
      }

      ul {
        li {
          list-style-type: disc;
        }
      }
      ol {
        li {
          list-style-type: decimal;
        }
      }
    }
  }
`;

const PrivacyPolicyPage = ({ data }) => {
  const { title, description, title_tag, meta_description } = data.main.data;

  return (
    <Layout>
      <Seo title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="inner-wrapper">
          <h1 className="title">{title.text}</h1>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
        </div>
      </Wrapper>
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const dataQuery = graphql`
  {
    main: prismicPrivacyPage {
      data {
        title {
          text
        }
        description {
          html
        }
        meta_description
        title_tag
      }
    }
  }
`;
